import React, { useContext, useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPencilRuler,
  faPlus,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
const Perfil = ({ seleccionado }) => {
  const [editarperfil, seteditarperfil] = useState(true);
  const [vbutton, setvbutton] = useState(true);
  const [vmenu, setvmenu] = useState(true);
  const { getPerfil, perfil,aPerfil,ePerfil,resaperfil,reseperfil } = useContext(UsuarioContext);
  const [elperfil, setElperfil] = useState({
    estatura: "",
    fecha_nacimiento: "",
    id: "",
    paciente: "",
    peso: "",
    preferencia: "",
    sexo: "",
  });

  const perfilcambia = (e) => {

    setElperfil({ ...elperfil, [e.target.name]: e.target.value });

  };
  const editar = () => {
    setvbutton(false);
    seteditarperfil(false);
  };
  const guardar = () => {
    setvbutton(true);
    seteditarperfil(true);

if (elperfil.id > 0){

    ePerfil(elperfil)
}else{
    aPerfil({...elperfil,paciente:seleccionado})
}



  };
  useEffect(() => {
    if (seleccionado == 0) {
      setvmenu(true);
      getPerfil({ paciente: seleccionado });

    } else {
      setvmenu(false);

      getPerfil({ paciente: seleccionado });
    }
  }, [seleccionado]);

  useEffect(() => {
    if (perfil.length == 0) {
        setElperfil({
            estatura: "",
            fecha_nacimiento: "",
            id: "",
            paciente: "",
            peso: "",
            preferencia: "",
            sexo: "",
          });
      
    } else {
      setElperfil(perfil[0]);
    }
  }, [perfil]);


  return (
    <div>
      <Row>
        <Col md={2} className="p-1 m-0">
          <FloatingLabel className="fs-6 fw-bolder p-1 m-1" label="Genero">
            <select
            name="sexo"
              value={elperfil.sexo}
              onChange={perfilcambia}
              className=" form-control fs-6 fw-medium p-1 m-1"
              disabled={editarperfil}
            >
              <option value={0}>Selecciona</option>
              <option value={1}>Femenino</option>
              <option value={2}>Masculino</option>
            </select>
          </FloatingLabel>
        </Col>
        <Col md={3} className="p-1 m-0">
          <FloatingLabel
            className="fs-6 fw-bolder p-1 m-1"
            label="Fecha de Nacimiento"
          >
            <Form.Control
              className=" form-control fs-6 fw-medium p-1 m-1"
              name="fecha_nacimiento"
              value={elperfil.fecha_nacimiento}
              type="date"
              placeholder="dd/mm/yyyy"
              onChange={perfilcambia}
              readOnly={editarperfil}
            />
          </FloatingLabel>
        </Col>
        <Col md={2} className="p-1 m-0">
          <FloatingLabel
            className="fs-6 fw-bolder p-1 m-1"
            label="Estatura (m)"
          >
            <Form.Control
              className=" form-control fs-6 fw-medium p-1 m-1"
              name="estatura"
              value={elperfil.estatura}
              type="number"
              min={0.1}
              max={3}
              placeholder="0.00"
              onChange={perfilcambia}
              readOnly={editarperfil}
            />
          </FloatingLabel>
        </Col>
        <Col md={2} className="p-1 m-0">
          <FloatingLabel className="fs-6 fw-bolder p-1 m-1" label="Peso (Kg)">
            <Form.Control
              className=" form-control fs-6 fw-medium p-1 m-1"
              name="peso"
              value={elperfil.peso}
              type="number"
              placeholder="0.00"
              min={5}
              max={200}
              onChange={perfilcambia}
              readOnly={editarperfil}
            />
          </FloatingLabel>
        </Col>
        <Col md={2} className="p-1 m-0">
          <FloatingLabel
            className="fs-6 fw-bolder p-1 m-1"
            label="Prefiera atención con?"
          >
            <select
            name="preferencia"
              value={elperfil.preferencia}
              className=" form-control fs-6 fw-medium p-1 m-1"
              disabled={editarperfil}
              onChange={perfilcambia}
            >
              <option value={3}>Selecciona</option>
              <option value={1}>Hombres</option>
              <option value={2}>Mujeres</option>
              <option value={3}>Ambos</option>
            </select>
          </FloatingLabel>
        </Col>
        <Col md={1} className=" p-2 m-0" hidden={vmenu}>
          {vbutton ? (
            <Button
              onClick={editar}
              className="form-control btn  fs-4 fw-bolder p-1 mt-3"
            >
              {" "}
              <FontAwesomeIcon icon={faPencilAlt} />{" "}
            </Button>
          ) : (
            <Button
              onClick={guardar}
              className="form-control btn  fs-4 fw-bolder p-1 mt-3"
            >
              {" "}
              <FontAwesomeIcon icon={faSave} />{" "}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Perfil;
