import {
  faCancel,
  faPencil,
  faPlus,
  faSave,
  faUserAltSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import UsuarioContext from "../context/usuarios/UsuarioContext";
const Diagnosticos = ({ seleccionado }) => {
  const {
    getExpediente,
    expediente,
    AgregarDiagnostico,
    EditarDiagnostico,
    resdadd,
    resdedit,  
    setDS, 
    PacienteSeleccionado,
    DiagnosticoSeleccionado,
  } = useContext(UsuarioContext);

  const [adiag, setadiag] = useState(true);
  const [ediag, setediag] = useState(true);
  const [bdiag, setbdiag] = useState(true);
  const [gdiag, setgdiag] = useState(true);
  const [cdiag, setcdiag] = useState(true);
  const [editardiag, seteditardiag] = useState(true);
  const [dselect, setdselect] = useState(0);
  const [diagnostico, setDiagnostico] = useState({
    id: "",
    id_paciente: "",
    nombre: "",
    diagnostico_corto: "",
    diagnostico: "",
    fecha: "",
    tratamiento: "",
    estatus: "",
    doctor: "",
  });

  const diagnosticoSeleccionado = (e) => {
    setDS(e.target.value)
    setdselect(e.target.value);
    if (e.target.value > 0) {
      setediag(false);
      setcdiag(true);
      setadiag(true);
      setbdiag(false);
      setgdiag(true);

      const diag = expediente.filter((c) => c.id === e.target.value);
      setDiagnostico(diag[0]);
      
    } else {
      setediag(true);
      setcdiag(true);
      setadiag(false);
      setbdiag(true);
      setgdiag(true);
      resetDiagnostico();
    }
  };

  const resetDiagnostico = () => {
    setDiagnostico({
      id: "",
      id_paciente: "",
      nombre: "",
      diagnostico_corto: "",
      diagnostico: "",
      fecha: "",
      tratamiento: "",
      estatus: "",
      doctor: "",
    });
    setDS(0)
  };

  const AddDiagnostico = (e) => {
    seteditardiag(false);
    setadiag(true);
    setediag(true);
    setbdiag(true);
    setgdiag(false);
    setcdiag(false);
    setDiagnostico({ ...diagnostico, id_paciente: seleccionado });
  };
  const DiagnosticoCambia = (e) => {
    setDiagnostico({ ...diagnostico, [e.target.name]: e.target.value });
  };
  const EditDiagnostico = () => {
    seteditardiag(false);
    setediag(true);
    setbdiag(true);
    setgdiag(false);
    setcdiag(false);
  };
  const CancelarDiagnostico = () => {
    if (dselect == 0) {
      setadiag(false);
      setediag(true);
      setbdiag(true);
      setgdiag(true);
      setcdiag(true);
      seteditardiag(true);
      resetDiagnostico();
    } else {
      setadiag(true);
      setediag(false);
      setbdiag(false);
      setgdiag(true);
      setcdiag(true);
      seteditardiag(true);
      resetDiagnostico();
    }
  };
  const BorrarDiagnostico = () => {
    seteditardiag(true);
    setediag(true);
    setbdiag(true);
    setgdiag(true);
    setcdiag(true);
    EditarDiagnostico({ ...diagnostico, estatus: 0 });
  };
  const GuardarDiagnostico = () => {
    seteditardiag(true);
    setediag(false);
    setbdiag(false);
    setgdiag(true);
    setcdiag(true);

    console.log(diagnostico.id )
    if (diagnostico.id > 0){
        EditarDiagnostico(diagnostico)
    }else{
      AgregarDiagnostico(diagnostico);  
    }
    
  };

  useEffect(() => {
    resetDiagnostico()
    if (seleccionado == 0) {
      setadiag(true);
      setbdiag(true);
      setcdiag(true);
      setediag(true);
      setgdiag(true);
      getExpediente(0)
    } else {
      getExpediente(seleccionado);
      setadiag(false);
      setbdiag(true);
      setcdiag(true);
      setediag(true);
      setgdiag(true);
      
    }
  }, [PacienteSeleccionado]);

  useEffect(() => {
    if (dselect == 0) {
      resetDiagnostico();

      if (seleccionado > 0) {
        setadiag(false);
      } else {
        setadiag(true);
      }
    } else {
    }
  }, [dselect]);

  useEffect(() => {

resetDiagnostico()
getExpediente(seleccionado);
  }, [resdadd]);
  useEffect(() => {

    resetDiagnostico()
    getExpediente(seleccionado);
      }, [resdedit]);

  return (
    <>
      <Row>
        <Col md={12}>
          <Row className=" justify-content-end">
            <Col md={4} className=" text-muted fs-4 text-center">
              {" "}
              Diagnósticos
            </Col>
            <Col md={4} className=" text-muted fs-4 text-center">
              <Form.Select
                className=" form-control fs-6 fw-medium p-1 m-1"
                onChange={diagnosticoSeleccionado}
              >
                <option value={0}>Selecciona Diagnostico</option>
                {expediente.map((elemento) => {
                  return (
                    <option key={elemento.id} value={elemento.id}>
                      {elemento.diagnostico_corto}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col md={4}>
              <Row>
                <Col className="col-12">
                  <Button
                    className="btn-block"
                    hidden={adiag}
                    onClick={AddDiagnostico}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPlus} /> Agegar
                  </Button>
                </Col>
                <Col className="col-6">
                  <Button
                    className="btn-block"
                    hidden={ediag}
                    onClick={EditDiagnostico}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPencil} /> Editar
                  </Button>
                </Col>
                <Col className="col-6">
                  <Button
                    className="btn-block"
                    hidden={bdiag}
                    onClick={BorrarDiagnostico}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUserAltSlash} /> Borrar
                  </Button>
                </Col>
                <Col className="col-6">
                  <Button
                    className="btn-block"
                    hidden={gdiag}
                    onClick={GuardarDiagnostico}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faSave} /> Guardar
                  </Button>
                </Col>
                <Col className="col-6">
                  <Button
                    className="btn-block"
                    hidden={cdiag}
                    onClick={CancelarDiagnostico}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faCancel} /> Cancelar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col md={6}>
          <FloatingLabel className="fs-6 fw-bolder p-1 m-1" label="Doctor">
            <Form.Control
              className=" form-control fs-6 fw-medium p-1 m-1"
              name="doctor"
              value={diagnostico.doctor}
              onChange={DiagnosticoCambia}
              type="text"
              placeholder="Doctor"
              readOnly={editardiag}
            />
          </FloatingLabel>
        </Col>
        <Col md={6}>
          <FloatingLabel className="fs-6 fw-bolder p-1 m-1" label="Diagnostico">
            <Form.Control
              className=" form-control fs-6 fw-medium p-1 m-1"
              name="diagnostico_corto"
              value={diagnostico.diagnostico_corto}
              onChange={DiagnosticoCambia}
              type="text"
              placeholder="Diagnóstico"
              readOnly={editardiag}
            />
          </FloatingLabel>
        </Col>
        <Col md={6}>
          <FloatingLabel className="fs-6 fw-bolder p-1 m-1" label="Detalles">
            <Form.Control
              as="textarea"
              className=" form-control fs-6 fw-medium pt-3 m-1"
              name="diagnostico"
              value={diagnostico.diagnostico}
              onChange={DiagnosticoCambia}
              type="text"
              minLength={20}
              placeholder="Detalles del diagnóstico"
              style={{ height: "100px" }}
              readOnly={editardiag}
            />
          </FloatingLabel>
        </Col>

        <Col md={6}>
          <FloatingLabel className="fs-6 fw-bolder p-1 m-1" label="Tratamiento">
            <Form.Control
              as="textarea"
              className=" form-control fs-6 fw-medium pt-3 m-1"
              name="tratamiento"
              value={diagnostico.tratamiento}
              onChange={DiagnosticoCambia}
              type="text"
              placeholder="Tratamiento"
              minLength={10}
              readOnly={editardiag}
              style={{ height: "100px" }}
            />
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
};

export default Diagnosticos;
