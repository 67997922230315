
import dayjs from "dayjs";

import { faRefresh,faCalendarXmark,faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Modal } from "react-bootstrap";
export default function CheckEvento({setcheck,evento}) {

  console.log(evento)
  return (

    <Modal show={true} size="xl" centered >
      <Modal.Header  className="p-2 m-0 bg-warning" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Información de la Cita
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="row">
   
            
            <div className=" col-12 col-sm-12 col-md-4 col-lg-4">
               <label className="fs-6 fw-bold p-1">Paciente</label>
                 <input className='form-control'  type="text" name='paciente' id='paciente' value={evento.paciente}  readOnly/> 
            </div>
            <div className=" col-12 col-sm-12 col-md-4 col-lg-4">
               <label className="fs-6 fw-bold p-1">Doctor</label>
                 <input className='form-control'  type="text" name='paciente' id='paciente' value={evento.doctor}  readOnly/> 
            </div>
            <div className=" col-12 col-sm-12 col-md-4 col-lg-4">
               <label className="fs-6 fw-bold p-1">Diagnostico</label>
                 <input className='form-control'  type="text" name='paciente' id='paciente' value={evento.diagnostico}  readOnly/> 
            </div>
            <div className=" col-12 col-sm-12 col-md-4 col-lg-4">
              
                <label className="fs-6 fw-bold p-1">Terapía</label>
                <input className='form-control' type="text" name='terapia' id='terapia' value={evento.terapia}  readOnly/>             
            </div>
            <div className=" col-12 col-sm-12 col-md-4 col-lg-4">
              
                <label className="fs-6 fw-bold p-1">Aparato</label>
                <div className="col-md-4   ">
          
          <div className="form-control fs-6 fw-semibold h-50">
            { JSON.parse(evento.aparato).map((element) => {
              return <Badge> {element.label}</Badge>;
            })}
          </div>
        </div>
              </div>

              <div className=" col-12 col-sm-12 col-md-4 col-lg-4   ">
                <label className="fs-6 fw-bold p-1">Fecha de Cita</label>
                <input type="date" step ='60' className='form-control' name="fecha" id="fecha" value={dayjs(evento.start).format('YYYY-MM-DD')}  readOnly/>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4  ">
                <label className="fs-6 fw-bold p-1">Hora Inicia</label>
                <input type="time"  step ='60'  className='form-control'  name="inicio" id="inicio" value={dayjs(evento.start).format('HH:mm:ss')}  readOnly/>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4  ">
                <label className="fs-6 fw-bold p-1">Hora Termina</label>
                <input type="time"  step ='60'  className='form-control'  name="fin" id="fin" value={dayjs(evento.end).format('HH:mm:ss')}  readOnly/>
              </div>

              <div className="col-12 d-flex justify-content-end align-content-end   mt-2">
          
                <button
                  className="btn btn-primary btn-block fs-6 fw-bold"
                  onClick={() => {setcheck(false)}}
                >
                 <FontAwesomeIcon icon={faRightFromBracket}  /> cerrar
                </button>
              </div>
         </Modal.Body>
      </Modal>



  );
}
