import { faCancel, faPencil, faSave, faUserAltSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import UsuarioContext from "../context/usuarios/UsuarioContext";

export const Terapeutas = () => {
  const {
    PacienteSeleccionado,
    setPS,
    getTerapeutas,
    terapeutas,
    terapeuta,
    getTerapeuta,
    usuario,
    EditUsuario,
    resedit,
  } = useContext(UsuarioContext);
  const [edatos, setedatos] = useState(true);
  const [bdatos, setbdatos] = useState(true);
  const [gdatos, setgdatos] = useState(true);
  const [cdatos, setcdatos] = useState(true);
  const [editardatos, seteditardatos] = useState(true);
  const [Seleccionado, setSeleccionado] = useState(true);
  const [Elpaciente, setElpaciente] = useState({
    id: "",
    usuario: "",
    password: "",
    nombre: "",
    correo: "",
    telefono: "",
    rol: "",
    estatus: "",
  });

  const EditDatos = () => {
    seteditardatos(false);
    setedatos(true);
    setbdatos(true);
    setgdatos(false);
    setcdatos(false);
  };
  const CancelarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setbdatos(false);
    setgdatos(true);
    setcdatos(true);
  };
  const BorrarDatos = () => {
    seteditardatos(true);
    setedatos(true);
    setbdatos(true);
    setgdatos(true);
    setcdatos(true);
    EditUsuario({ ...Elpaciente, estatus: "0" });
  };
  const GuardarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setbdatos(false);
    setgdatos(true);
    setcdatos(true);
    EditUsuario(Elpaciente);
  };

  const pacienteCambia = (e) => {
    setElpaciente({ ...Elpaciente, [e.target.name]: e.target.value });
  };
  const pacienteSeleccionado = (e) => {
    getTerapeuta(usuario[0].id,usuario[0].rol,e.target.value)
  };
  React.useEffect(() => {
    getTerapeutas(usuario[0].id, usuario[0].rol, 2);
  }, []);



  React.useEffect(() => {
    console.log(terapeuta)
if (terapeuta.length > 0){
    setElpaciente(terapeuta[0])
}

  }, [terapeuta]);

  React.useEffect(() => {
    console.log(Seleccionado)
if (Seleccionado > 0){
    getTerapeuta(usuario[0].id,usuario[0].rol,Seleccionado)
}

  }, [Seleccionado]);

  console.log(terapeuta)
  return (
    <>
      <Container>
        <Row className=" justify-content-between">
          <Col
            md={12}
            className=" bg-light border border-secondary rounded-2 p-2 m-0  shadow"
          >
            <Row className="d-flex">
              <Col md={4}>
                <div className="text-muted fs-4 text-center">
                  Datos del Terapeuta
                </div>
              </Col>
              <Col md={4}>
                <Form.Select
                  value={Seleccionado}
                  className=" form-control fs-6 fw-medium p-1 m-1"
                  onChange={(e)=>{setSeleccionado(e.target.value)}}
                >
                  <option value={0}>Selecciona Paciente</option>
                  {terapeutas.map((elemento) => {
                    return (
                      <option key={elemento.id} value={elemento.id}>
                        {elemento.nombre}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={4}>
                <Row className=" justify-content-end">
                  <Col className="col-6">
                    <Button
                      className="btn-block"
                      hidden={edatos}
                      onClick={EditDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faPencil} /> Editar
                    </Button>
                  </Col>
                  <Col className="col-6">
                    <Button
                      className="btn-block"
                      hidden={bdatos}
                      onClick={BorrarDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faUserAltSlash} /> Borrar
                    </Button>
                  </Col>
                  <Col className="col-6">
                    <Button
                      className="btn-block"
                      hidden={gdatos}
                      onClick={GuardarDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faSave} /> Guardar
                    </Button>
                  </Col>
                  <Col className="col-6">
                    <Button
                      className="btn-block"
                      hidden={cdatos}
                      onClick={CancelarDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faCancel} /> Cancelar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Usuario"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="usuario"
                    value={Elpaciente.usuario}
                    type="text"
                    placeholder="Usuario"
                    onChange={pacienteCambia}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Password"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="password"
                    value={Elpaciente.password}
                    type="password"
                    placeholder="Password"
                    onChange={pacienteCambia}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Nombre completo"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="nombre"
                    value={Elpaciente.nombre.toUpperCase()}
                    type="text"
                    minLength={20}
                    placeholder="Nombre completo"
                    onChange={pacienteCambia}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Correo"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="correo"
                    value={Elpaciente.correo}
                    type="email"
                    placeholder="nombre@dominio.com"
                    onChange={pacienteCambia}
                    minLength={10}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Teléfono"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="telefono"
                    value={Elpaciente.telefono}
                    type="number"
                    placeholder="##-####-####"
                    onChange={pacienteCambia}
                    minLength={10}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="rol">
                  <FloatingLabel
                    className="fs-6 fw-bolder p-1 m-1"
                    label="Tipo de usuario"
                  >
                    <Form.Select
                      className=" form-control fs-6 fw-medium p-1 m-1"
                      value={Elpaciente.rol}
                      name="rol"
                      onChange={pacienteCambia}
                      disabled={editardatos}
                    >
                      <option value="0">Selecciona Tipo</option>
                      <option value="1">Paciente</option>
                      <option value="2">Terapeuta</option>
                      <option value="3">Administrador</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
