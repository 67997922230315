import {  useContext, useEffect } from 'react';
import UsuarioContext from '../context/usuarios/UsuarioContext.js';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import App from '../App.js';
import { Link } from 'react-router-dom';

export default function Logout() {

const {setUsuario} = useContext(UsuarioContext)

useEffect(() => {
  localStorage.setItem("log", JSON.stringify([]));
  setUsuario([])
 },[])



  return (
    <div>
    <h1>Users</h1>
    <ul>
     
        <li>
          <Link to={"/"}>Salir</Link>
        </li>
     
    </ul>
  </div>
  );
}







