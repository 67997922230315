import React ,{ useContext}from 'react'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import UsuarioContext from "../context/usuarios/UsuarioContext.js";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';

export default function Pacientes() {
  const { usuario,pacientes,  getPacientes } = useContext(UsuarioContext);
  React.useEffect(() => {
    getPacientes(usuario[0].id, usuario[0].rol,1);
  },[])




  const botonExpediente = (pacientes) => {
    return (
        <div className="flex align-items-center gap-2">
          <Link className='btn btn-primary' to={`/expediente/${pacientes.id}`}>Expediente</Link>
        </div>
    );
};
  return ( 
  <div className="text-left p-2 m-2 pt-4 mt-4 ">
   <DataTable value={pacientes} paginator rows={10} rowsPerPageOptions={[10,20,30,40,50]} tableStyle={{ minWidth: '50rem' }}>
    <Column field="nombre" header="Nombre:" style={{ width: '30%' }}></Column>
    <Column field="telefono" header="Teléfono:" style={{ width: '30%' }}></Column>
    <Column field="correo" header="Correo:" style={{ width: '30%' }}></Column>
    <Column header="Representative" body={botonExpediente} style={{ width: '30%' }}></Column>
</DataTable>
</div>
  )
}

