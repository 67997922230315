import React, { useContext, useEffect } from "react";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";
import { Badge, DropdownButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky, faStickyNote } from "@fortawesome/free-solid-svg-icons";
export default function Terapias() {
  const {
    getSesiones,
    sesiones,
    PacienteSeleccionado,
    DiagnosticoSeleccionado,
  } = useContext(UsuarioContext);

  useEffect(() => {
    getSesiones(PacienteSeleccionado, DiagnosticoSeleccionado);
  }, [PacienteSeleccionado, DiagnosticoSeleccionado]);

  console.log(sesiones);

  if (sesiones) {
    return (
      <div className="col-12 m-0 p-0 ">
        <div className="d-none d-sm-none d-md-block d-lg-block">
          <div className="col-12 row border border-secondary-subtle m-0 p-0 rounded 	 ">
            <div className="col-md-1 ">Terapia</div>
            <div className="col-md-1 ">N.Sesiones</div>
            <div className="col-1 ">Tomadas</div>
            <div className="col-2 ">Aparatos</div>
            <div className="col-2 ">F.Inicio</div>
            <div className="col-2 ">F.Fin</div>
            <div className="col-2 ">Costo</div>
            <div className="col-1 ">Nota</div>
          </div>
        </div>

        {sesiones.map((elemento) => {
          return (
            <div
              key={elemento.id}
              className="col-12 row border border-secondary-subtle m-0 p-0 rounded"
            >
              <div className="col-3  d-md-none ">Terapia</div>
              <div className="col-9 col-sm-9 col-md-1 col-lg-1 ">
                {elemento.terapia}
              </div>
              <div className="col-3  d-md-none ">N.Sesiones</div>
              <div className="col-3 col-sm-3 col-md-1 col-lg-1">
                {elemento.sesiones}
              </div>
              <div className="col-3  d-md-none ">Tomadas</div>
              <div className="col-3 col-sm-3 col-md-1 col-lg-1 ">
                {elemento.sesiones_tomadas}
              </div>
              <div className="col-3  d-md-none ">Aparatos</div>
              <div className="col-9 col-sm-9 col-md-2 col-lg-2 ">
                {JSON.parse(elemento.aparatos).map((apa) => {
                  return <Badge bg="primary">{apa.label}</Badge>;
                })}
              </div>
              <div className="col-3  d-md-none ">F.Inicio</div>
              <div className="col-3 col-sm-3 col-md-2 col-lg-2 ">
                {dayjs(elemento.fecha_inicio).format("DD/MMMM/YYYY")}
              </div>
              <div className="col-3  d-md-none ">F.Fin</div>
              <div className="col-3 col-sm-3 col-md-2 col-lg-2 ">
                {dayjs(elemento.fecha_fin).format("DD/MMMM/YYYY")}
              </div>
              <div className="col-3  d-md-none ">Costo</div>
              <div className="col-3 col-sm-3 col-md-2 col-lg-2 ">
                ${" "}
                <NumericFormat
                  value={elemento.costo}
                  displayType="text"
                  thousandSeparator
                />
              </div>
              <div className="col-3  d-md-none ">Nota</div>
              <div className="col-3 col-sm-3 col-md-1 col-lg-1 ">
              <DropdownButton id="dropdown-basic-button" title={<FontAwesomeIcon icon={ faStickyNote }/>}>
                        <div className="p-1">{elemento.nota}</div>
                       </DropdownButton>
                  
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
